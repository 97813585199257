export const environment = {
    production: false,
    baseUrl: process.env.NX_SITE_URL,
    apiGatewayUrl: process.env.NX_API_GATEWAY_URL,
    clientId:
        process.env.NX_USE_AUTH0 === 'true'
            ? process.env.NX_AUTH0_CLIENT_ID
            : process.env.NX_AUTH_CLIENT_ID,
    idp:
        process.env.NX_USE_AUTH0 === 'true'
            ? `https://${process.env.NX_AUTH0_SERVER_BASE}`
            : process.env.NX_AUTH_ENDPOINT,
    frontendUrl: process.env.NX_FRONTEND_URL,
    thegameApiUrl: process.env.NX_THEGAME_API_URL,
    auth0Audience: process.env.NX_AUTH0_AUDIENCE,
}
